html, body {
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-initialized {
  padding: 0 !important;
}

.solution .slick-track {
  margin-left: -197px;
}

@media screen and (min-width: 2300px) {
  .solution .slick-track {
    margin-left: -215px;
  }
}

@media screen and (max-width: 1367px) {
  .solution .slick-track {
    margin-left: -130px;
  }
}

@media screen and (max-width: 1367px) {
  .solution .slick-track {
    margin-left: -130px;
  }
}

@media screen and (max-width: 1200px) {
  .solution .slick-track {
    margin-left: -199px;
  }
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  .solution .slick-track {
    margin-left: -180px;
  }
}
@media screen and (max-width: 768px) {
  .solution .slick-track {
    margin-left: 0;
    margin: 0 auto;
  }
}

.slick-slide div {
  outline: none !important;
}

/* @media screen and (max-width: 576px) {
  .solution .slick-track {
    margin-left: -140px;
  } */

/* } */
